import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SendMoneyForm.css';
import Receipt from './Receipt';

const SendMoneyForm = () => {
  const usrdetails = JSON.parse(sessionStorage.getItem("userDetails")) || {};
  const ComID = usrdetails.CompanyID || '';
  const MaiAgID = usrdetails.MainAgentID || '';
  const SubAgID = usrdetails.SubAgentID || '';
  const [worldData, setWorldData] = useState({});
  const [receiverOptions, setReceiverOptions] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receiptData, setReceiptData] = useState(null);
  const [formData, setFormData] = useState({
    companyID: ComID,
    mainagentID: MaiAgID,
    subagentID: SubAgID,
    customerTelephone: '',
    senderDetails: {
      senderid: '',
      name: '',
      phone: '',
      country: '',
      state: '',
      city: '',
      address: '',
      idNumber: '',
      idExp: '',
      idIssue: '',
      documentType: '',
      docUpload: ''
    },
    receiverDetails: {
      name: '',
      phone: '',
      country: '',
      state: '',
      city: '',
      id: ''
    },
    transactionDetails: {
      amount: '',
      commission: '',
      totalAmount: '',
      serviceType: '',
      accNum: '',
      bankName: '',
      exchangeRate: '',
      sendingReason: '',
      relationship: ''
    }
  });
  const [formErrors, setFormErrors] = useState({});

  const fetchExchangeRate = (baseCurrency, targetCurrency) => {
    axios.get(`https://api.frankfurter.app/latest?from=${baseCurrency}`)
      .then(response => {
        const rate = response.data.rates[targetCurrency];
        setExchangeRate(rate);
        setFormData(prevState => ({
          ...prevState,
          transactionDetails: {
            ...prevState.transactionDetails,
            exchangeRate: rate
          }
        }));
      })
      .catch(error => {
        console.error('Error fetching exchange rate:', error);
      });
  };

  useEffect(() => {
    setWorldData({
       USA: {
  Alabama: ["Birmingham", "Montgomery", "Mobile"],
  Alaska: ["Anchorage", "Fairbanks", "Juneau"],
  Arizona: ["Phoenix", "Tucson", "Mesa"],
  Arkansas: ["Little Rock", "Fayetteville", "Fort Smith"],
  California: ["Los Angeles", "San Diego", "Sacramento", "San Francisco", "San Jose"],
  Colorado: ["Denver", "Colorado Springs", "Aurora"],
  Connecticut: ["Hartford", "New Haven", "Stamford"],
  Delaware: ["Wilmington", "Dover", "Newark"],
  Florida: ["Miami", "Orlando", "Tampa", "Jacksonville", "Tallahassee"],
  Georgia: ["Atlanta", "Savannah", "Augusta"],
  Hawaii: ["Honolulu", "Hilo", "Kailua"],
  Idaho: ["Boise", "Meridian", "Nampa"],
  Illinois: ["Chicago", "Springfield", "Naperville"],
  Indiana: ["Indianapolis", "Fort Wayne", "Evansville"],
  Iowa: ["Des Moines", "Cedar Rapids", "Davenport"],
  Kansas: ["Wichita", "Overland Park", "Kansas City"],
  Kentucky: ["Louisville", "Lexington", "Bowling Green"],
  Louisiana: ["New Orleans", "Baton Rouge", "Shreveport"],
  Maine: ["Portland", "Augusta", "Bangor"],
  Maryland: ["Baltimore", "Annapolis", "Frederick"],
  Massachusetts: ["Boston", "Worcester", "Springfield"],
  Michigan: ["Detroit", "Grand Rapids", "Lansing"],
  Minnesota: ["Minneapolis", "Saint Paul", "Rochester"],
  Mississippi: ["Jackson", "Gulfport", "Biloxi"],
  Missouri: ["Kansas City", "Saint Louis", "Springfield"],
  Montana: ["Billings", "Missoula", "Bozeman"],
  Nebraska: ["Omaha", "Lincoln", "Bellevue"],
  Nevada: ["Las Vegas", "Reno", "Henderson"],
  "New Hampshire": ["Manchester", "Nashua", "Concord"],
  "New Jersey": ["Newark", "Jersey City", "Paterson"],
  "New Mexico": ["Albuquerque", "Santa Fe", "Las Cruces"],
  "New York": ["New York City", "Buffalo", "Rochester"],
  "North Carolina": ["Charlotte", "Raleigh", "Greensboro"],
  "North Dakota": ["Fargo", "Bismarck", "Grand Forks"],
  Ohio: ["Columbus", "Cleveland", "Cincinnati"],
  Oklahoma: ["Oklahoma City", "Tulsa", "Norman"],
  Oregon: ["Portland", "Salem", "Eugene"],
  Pennsylvania: ["Philadelphia", "Pittsburgh", "Allentown"],
  "Rhode Island": ["Providence", "Cranston", "Warwick"],
  "South Carolina": ["Charleston", "Columbia", "Greenville"],
  "South Dakota": ["Sioux Falls", "Rapid City", "Aberdeen"],
  Tennessee: ["Nashville", "Memphis", "Knoxville"],
  Texas: ["Houston", "Austin", "Dallas", "San Antonio"],
  Utah: ["Salt Lake City", "West Valley City", "Provo"],
  Vermont: ["Burlington", "Montpelier", "Rutland"],
  Virginia: ["Virginia Beach", "Norfolk", "Richmond"],
  Washington: ["Seattle", "Spokane", "Tacoma", "Bellevue", "Kent"],
  "West Virginia": ["Charleston", "Huntington", "Morgantown"],
  Wisconsin: ["Milwaukee", "Madison", "Green Bay"],
  Wyoming: ["Cheyenne", "Casper", "Laramie"],
      },
      India: {
        Maharashtra: ["Mumbai", "Pune", "Nagpur"],
        TamilNadu: ["Chennai", "Madurai"],
        Karnataka: ["Bangalore", "Mangalore"],
      },
      Canada: {
        Alberta: ["Calgary", "Edmonton", "Red Deer"],
        BritishColumbia: ["Vancouver", "Kelowna"],
        Manitoba: ["Winnipeg", "Brandon"],
      },
      Germany: {
        Bavaria: ["Munich", "Nuremberg"],
        NorthRhine: ["Cologne", "Düsseldorf"],
      },
      Ethiopia: {
        "Addis Ababa": ["Addis Ababa"],
        "Dire Dawa": ["Dire Dawa"],
        Harar: ["Harar"],
        Wajaale: ["Wajaale"],
        Jijiga: ["Jijiga"],
        Mekele: ["Mekele"],
        Gonder: ["Gonder"],
        Jima: ["Jima"],
        Asela: ["Asela"],
        Adema: ["Adema"],
        Awash: ["Awash"],
        Dese: ["Dese"],
        "Bahir Dar": ["Bahir Dar"],
        Negele: ["Negele"],
        Shashemene: ["Shashemene"],
        Sodo: ["Sodo"],
        Bedesa: ["Bedesa"],
        Amhara: ["Amhara"],
      },
       Somalia: {
        Mogadishu: ["Bakaaro", "Warta-nabada", "Dayniile", "Howlwadaag", "Hodan", "Dharkanley", "Shibis","Daarasalaam", "Huriwaa", "Yaqshid"],
        South_West: ["Badhabo"],
        Hirshabelle: ["Jowhar","Beledweyne"],
        Galmudug: ["Dhuusamareeb","Adado","Elbuur","Galkacyo","Galhareeri"],
        Puntland: ["Bosaso","Qardho","Garowe"],
        Jubba_Land: ["Kismayo"],
        Somaliland: ["Hargeisa", "Borama"],
      }
    });
    fetchExchangeRate('USD', 'USD'); // Default fetch on mount, adjust as needed
  }, []);

  useEffect(() => {
    if (formData.customerTelephone) {
      axios.post('https://gogolow.com/backend/api/modules/search_client', { companyID: formData.companyID, customerID: formData.customerTelephone })
        .then(response => {
          if (response.data.status === 'success') {
            const customerDetails = response.data.CustomerDetails;
            console.log(response.data);
            setFormData(prevState => ({
              ...prevState,
              senderDetails: {
                senderid: customerDetails.CustomerID,
                name: customerDetails.Name,
                phone: customerDetails.PhoneNumber,
                idNumber: customerDetails.DriveLN,
                idExp: customerDetails.Exp,
                idIssue: customerDetails.issue,
                address: customerDetails.Address,
                city: customerDetails.city,
                country: customerDetails.Country,
                state: customerDetails.state,
                documentType: customerDetails.CustomerIdType,
                docUpload: customerDetails.DLPhoto // Update the state with the document URL
              }
            }));
            setUploadedDocument(customerDetails.DLPhoto); // Update the state for displaying the document
            setReceiverOptions(response.data.receiverdata || []);
          } else {
            // Reset form fields if not found
            setFormData(prevState => ({
              ...prevState,
              senderDetails: {
                name: '',
                phone: '',
                idNumber: '',
                idExp: '',
                idIssue: '',
                address: '',
                city: '',
                state: '',
                country: '',
                documentType: '',
                docUpload: ''
              },
              receiverDetails: {
                name: '',
                phone: '',
                country: '',
                state: '',
                city: '',
                id: ''
              },
              transactionDetails: {
                amount: '',
                commission: '',
                totalAmount: '',
                serviceType: '',
                accNum: '',
                bankName: '',
                exchangeRate: '',
                sendingReason: '',
                relationship: ''
              }
            }));
            setReceiverOptions([]);
            setUploadedDocument(null); // Clear the document display state
          }
        }).catch((error) => {
          console.log(error);
        });
    }
  }, [formData.customerTelephone, formData.companyID]);

  useEffect(() => {
    const amount = parseFloat(formData.transactionDetails.amount) || 0;
    const commission = parseFloat(formData.transactionDetails.commission) || 0;
    const totalAmount = amount * (1 + commission / 100);
    setFormData(prevState => ({
      ...prevState,
      transactionDetails: {
        ...prevState.transactionDetails,
        totalAmount: totalAmount.toFixed(2) // ensuring two decimal points
      }
    }));
  }, [formData.transactionDetails.amount, formData.transactionDetails.commission]);

  const isExpired = (date) => {
    return new Date(date) < new Date();
  };

  const isFutureDate = (date) => {
    return new Date(date) > new Date();
  };

  const isEmpty = (value) => {
    return value === '';
  };

  const validateForm = () => {
    let errors = {};
    let missingFields = []; // To collect the names of missing fields

    const fieldsToValidate = [
      { name: 'customerTelephone', section: 'customerTelephone', label: 'Customer Telephone' },
      { name: 'name', section: 'senderDetails', label: 'Sender Name' },
      { name: 'phone', section: 'senderDetails', label: 'Sender Phone' },
      { name: 'address', section: 'senderDetails', label: 'Sender Address' },
      { name: 'country', section: 'senderDetails', label: 'Sender Country' },
      { name: 'state', section: 'senderDetails', label: 'Sender State' },
      { name: 'city', section: 'senderDetails', label: 'Sender City' },
      { name: 'idNumber', section: 'senderDetails', label: 'Sender ID Number' },
      { name: 'idIssue', section: 'senderDetails', label: 'ID Issue Date' },
      { name: 'idExp', section: 'senderDetails', label: 'ID Expiry Date' },
      { name: 'documentType', section: 'senderDetails', label: 'Document Type' },
      { name: 'name', section: 'receiverDetails', label: 'Receiver Name' },
      { name: 'phone', section: 'receiverDetails', label: 'Receiver Phone' },
      { name: 'country', section: 'receiverDetails', label: 'Receiver Country' },
      { name: 'state', section: 'receiverDetails', label: 'Receiver State' },
      { name: 'city', section: 'receiverDetails', label: 'Receiver City' },
      { name: 'amount', section: 'transactionDetails', label: 'Transaction Amount' },
      { name: 'commission', section: 'transactionDetails', label: 'Commission' },
      { name: 'sendingReason', section: 'transactionDetails', label: 'Sending Reason' },
      { name: 'relationship', section: 'transactionDetails', label: 'Relationship' },
      { name: 'serviceType', section: 'transactionDetails', label: 'Service Type' },
    ];

    fieldsToValidate.forEach(({ name, section, label }) => {
      if (isEmpty(formData[section][name])) {
        errors[`${section}.${name}`] = true;
        missingFields.push(label); // Add the missing field's label to the array
      }
    });

    if (isFutureDate(formData.senderDetails.idIssue)) {
      errors['senderDetails.idIssue'] = true;
      missingFields.push('ID Issue Date (cannot be in the future)');
    }

    if (isExpired(formData.senderDetails.idExp)) {
      errors['senderDetails.idExp'] = true;
      missingFields.push('ID Expiry Date (has expired)');
    }

    // if (!file) {
    //   errors['documentUpload'] = true;
    //   missingFields.push('Document Upload');
    // }

    setFormErrors(errors);

    // If there are missing fields, alert the user
    if (missingFields.length > 0) {
      alert('The following required fields are missing or invalid:\n' + missingFields.join('\n'));
    }

    return missingFields.length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');
    setFormData(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value
      }
    }));

    // Remove error if the field is updated
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [`${section}.${field}`]: false,
    }));
  };

  const handleCustomerTelephoneChange = (e) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      customerTelephone: value
    }));

    // Remove error if the field is updated
    setFormErrors(prevErrors => ({
      ...prevErrors,
      'customerTelephone': false,
    }));
  };

  const handleCountryChange = (section) => (e) => {
    const country = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        country,
        state: '',
        city: ''
      }
    }));

    // Remove error if the field is updated
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [`${section}.country`]: false,
      [`${section}.state`]: false,
      [`${section}.city`]: false,
    }));
  };

  const handleStateChange = (section) => (e) => {
    const state = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        state,
        city: ''
      }
    }));

    // Remove error if the field is updated
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [`${section}.state`]: false,
      [`${section}.city`]: false,
    }));
  };

  const handleReceiverChange = (e) => {
    const receiverId = e.target.value;
    const selectedReceiver = receiverOptions.find(receiver => receiver.ReceiverID === receiverId);
    if (selectedReceiver) {
      setFormData(prevState => ({
        ...prevState,
        receiverDetails: {
          name: selectedReceiver.Name,
          phone: selectedReceiver.PhoneNumber,
          country: selectedReceiver.Country,
          state: selectedReceiver.State,
          city: selectedReceiver.City,
          id: selectedReceiver.ReceiverID
        }
      }));

      // Remove errors related to receiver fields
      setFormErrors(prevErrors => ({
        ...prevErrors,
        'receiverDetails.name': false,
        'receiverDetails.phone': false,
        'receiverDetails.country': false,
        'receiverDetails.state': false,
        'receiverDetails.city': false,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedDocument(reader.result);
        setFormData(prevState => ({
          ...prevState,
          senderDetails: {
            ...prevState.senderDetails,
            docUpload: reader.result // Store the base64 string in docUpload
          }
        }));
      };
      reader.readAsDataURL(file);

      // Remove file-related error
      setFormErrors(prevErrors => ({
        ...prevErrors,
        'documentUpload': false,
      }));
    }
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      alert('Please fill in all required fields correctly.');
      return;
    }

    setLoading(true);
    const formPayload = new FormData();
    formPayload.append('documentUpload', file); // Append the file

    // Append the other form fields
    Object.keys(formData).forEach(key => {
      if (typeof formData[key] === 'object' && formData[key] !== null) {
        Object.keys(formData[key]).forEach(nestedKey => {
          formPayload.append(`${key}[${nestedKey}]`, formData[key][nestedKey]);
        });
      } else {
        formPayload.append(key, formData[key]);
      }
    });

    axios.post('https://gogolow.com/backend/api/modules/save_remittence', formPayload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
     console.log(response.data);
      setLoading(false); // Stop loading animation

      if (response.data.status === 'success') {
        console.log("response.data");
        setReceiptData(response.data.row); // Set receipt data for displaying the receipt
        sessionStorage.setItem("receiptissued", JSON.stringify(response.data.row));
        sendConfirmationSMS(formData.senderDetails.phone, 'Your transaction was successful!'); // Send confirmation SMS

        window.location.href = "/Receipt";
      } else {
        console.log(response.data.status);
        alert(response.data.status && response.data.message);
      }
    }).catch(error => {
      setLoading(false); // Stop loading animation
      console.error('Error uploading the file', error);
    });
  };

  const sendConfirmationSMS = (phone, message) => {
    axios.post('https://gogolow.com/backend/api/modules/send-sms.js', {
      senderPhone: phone,
      message: message
    }).then(response => {
      console.log('SMS sent successfully:', response.data);
    }).catch(error => {
      console.error('Error sending SMS:', error);
    });
  };

  return (
    <div className="send-money-container">
      {loading && <div className="loading-spinner"></div>}
      {!loading && (
        <div className="send-money-form">
          <div className="form-header">
            <h1>Send Money Form</h1>
          </div>
          <div className="form-section">
            <div className="form-column">
              <h2>Sender Information</h2>
              <form>
                <input type="text" name="customerTelephone" value={formData.customerTelephone} onChange={handleCustomerTelephoneChange} placeholder="Search a customer..." className={formErrors['customerTelephone'] ? 'invalid' : ''} required />
                <input type="text" name="senderDetails.name" value={formData.senderDetails.name} onChange={handleInputChange} placeholder="Full Name" className={formErrors['senderDetails.name'] ? 'invalid' : ''} required />
                <input type="text" name="senderDetails.phone" value={formData.senderDetails.phone} onChange={handleInputChange} placeholder="Phone Number" className={formErrors['senderDetails.phone'] ? 'invalid' : ''} required />
                <input type="text" name="senderDetails.address" value={formData.senderDetails.address} onChange={handleInputChange} placeholder="Address" className={formErrors['senderDetails.address'] ? 'invalid' : ''} required />
                <select name="senderDetails.country" value={formData.senderDetails.country} onChange={handleCountryChange('senderDetails')} className={formErrors['senderDetails.country'] ? 'invalid' : ''} required>
                  <option value="">Select Country</option>
                  {Object.keys(worldData).map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                  ))}
                </select>
                <select name="senderDetails.state" value={formData.senderDetails.state} onChange={handleStateChange('senderDetails')} className={formErrors['senderDetails.state'] ? 'invalid' : ''} required>
                  <option value="">Select A State</option>
                  {worldData[formData.senderDetails.country] && Object.keys(worldData[formData.senderDetails.country]).map((state, index) => (
                    <option key={index} value={state}>{state}</option>
                  ))}
                </select>
                <select name="senderDetails.city" value={formData.senderDetails.city} onChange={handleInputChange} className={formErrors['senderDetails.city'] ? 'invalid' : ''} required>
                  <option value="">Select A City</option>
                  {worldData[formData.senderDetails.country] && worldData[formData.senderDetails.country][formData.senderDetails.state] && worldData[formData.senderDetails.country][formData.senderDetails.state].map((city, index) => (
                    <option key={index} value={city}>{city}</option>
                  ))}
                </select>
                <input type="text" name="senderDetails.idNumber" value={formData.senderDetails.idNumber} onChange={handleInputChange} placeholder="ID Number" className={formErrors['senderDetails.idNumber'] ? 'invalid' : ''} required />
                <input type="date" name="senderDetails.idIssue" value={formData.senderDetails.idIssue} onChange={handleInputChange} placeholder="Issue Date"
                  className={`${formErrors['senderDetails.idIssue'] ? 'invalid' : ''}`} required
                />
                <input type="date" name="senderDetails.idExp" value={formData.senderDetails.idExp} onChange={handleInputChange} placeholder="Exp. Date"
                  className={`${formErrors['senderDetails.idExp'] ? 'invalid' : ''}`} required
                />
                <select name="senderDetails.documentType" value={formData.senderDetails.documentType} onChange={handleInputChange} className={formErrors['senderDetails.documentType'] ? 'invalid' : ''} required>
                  <option value="">Select Document Type</option>
                  <option value="Passport">Passport</option>
                  <option value="Driver's License">Driver's License</option>
                  <option value="National ID">National ID</option>
                </select>
                <input type="file" name="documentUpload" onChange={handleFileChange}  />
                {uploadedDocument && (
                  <div>
                    <h3>Uploaded Document:</h3>
                    <img src={uploadedDocument} alt="Uploaded Document" style={{ width: '100px', height: '100px' }} />
                  </div>
                )}
              </form>
            </div>
            <div className="form-column">
              <h2>Receiver Information</h2>
              <form>
                <select name="receiverDetails.id" value={formData.receiverDetails.id} onChange={handleReceiverChange} className={formErrors['receiverDetails.id'] ? 'invalid' : ''} required>
                  <option value="">Add new receiver</option>
                  {receiverOptions && receiverOptions.map((receiver, index) => (
                    <option key={index} value={receiver.ReceiverID}>{receiver.Name}</option>
                  ))}
                </select>
                <input type="text" name="receiverDetails.name" value={formData.receiverDetails.name} onChange={handleInputChange} placeholder="Receiver Full Name" className={formErrors['receiverDetails.name'] ? 'invalid' : ''} required />
                <input type="text" name="receiverDetails.phone" value={formData.receiverDetails.phone} onChange={handleInputChange} placeholder="Phone Number" className={formErrors['receiverDetails.phone'] ? 'invalid' : ''} required />
                <select name="receiverDetails.country" value={formData.receiverDetails.country} onChange={handleCountryChange('receiverDetails')} className={formErrors['receiverDetails.country'] ? 'invalid' : ''} required>
                  <option value="">Select A Country</option>
                  {Object.keys(worldData).map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                  ))}
                </select>
                <select name="receiverDetails.state" value={formData.receiverDetails.state} onChange={handleStateChange('receiverDetails')} className={formErrors['receiverDetails.state'] ? 'invalid' : ''} required>
                  <option value="">Select A State</option>
                  {worldData[formData.receiverDetails.country] && Object.keys(worldData[formData.receiverDetails.country]).map((state, index) => (
                    <option key={index} value={state}>{state}</option>
                  ))}
                </select>
                <select name="receiverDetails.city" value={formData.receiverDetails.city} onChange={handleInputChange} className={formErrors['receiverDetails.city'] ? 'invalid' : ''} required>
                  <option value="">Select A City</option>
                  {worldData[formData.receiverDetails.country] && worldData[formData.receiverDetails.country][formData.receiverDetails.state] && worldData[formData.receiverDetails.country][formData.receiverDetails.state].map((city, index) => (
                    <option key={index} value={city}>{city}</option>
                  ))}
                </select>
                <input type="text" name="transactionDetails.amount" value={formData.transactionDetails.amount} onChange={handleInputChange} placeholder="Amount" className={formErrors['transactionDetails.amount'] ? 'invalid' : ''} required />
                <select name="transactionDetails.commission" value={formData.transactionDetails.commission} onChange={handleInputChange} className={formErrors['transactionDetails.commission'] ? 'invalid' : ''} required>
                  <option value="">Commission%</option>
                  <option value="1">1%</option>
                  <option value="2">2%</option>
                  <option value="3">3%</option>
                </select>
                <input type="text" name="transactionDetails.totalAmount" value={formData.transactionDetails.totalAmount} onChange={handleInputChange} placeholder="Total Collected" required />
                <input type="text" name="transactionDetails.exchangeRate" value={formData.transactionDetails.exchangeRate} onChange={handleInputChange} placeholder="Exchange Rate" />
                <select name="transactionDetails.sendingReason" value={formData.transactionDetails.sendingReason} onChange={handleInputChange} className={formErrors['transactionDetails.sendingReason'] ? 'invalid' : ''} required>
                  <option value="">Reason for Sending</option>
                  <option value="Family Support">Family Support</option>
                  <option value="Education">Education</option>
                  <option value="Buying Property">Buying Property</option>
                </select>
                <select name="transactionDetails.relationship" value={formData.transactionDetails.relationship} onChange={handleInputChange} className={formErrors['transactionDetails.relationship'] ? 'invalid' : ''} required>
                  <option value="">Relationship</option>
                  <option value="Parent">Parent</option>
                  <option value="Sibling">Sibling</option>
                  <option value="Friend">Friend</option>
                </select>
                <select name="transactionDetails.serviceType" value={formData.transactionDetails.serviceType} onChange={handleInputChange} className={formErrors['transactionDetails.serviceType'] ? 'invalid' : ''} required>
                  <option value="">Service Type</option>
                  <option value="Cash Pick up">Cash Pick up</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                </select>
                <input type="text" name="transactionDetails.accNum" value={formData.transactionDetails.accNum} onChange={handleInputChange} placeholder="Account Number" hidden={formData.transactionDetails.serviceType !== 'Bank Transfer'} required={formData.transactionDetails.serviceType === 'Bank Transfer'} />
                <input type="text" name="transactionDetails.bankName" value={formData.transactionDetails.bankName} onChange={handleInputChange} placeholder="Bank Name" hidden={formData.transactionDetails.serviceType !== 'Bank Transfer'} required={formData.transactionDetails.serviceType === 'Bank Transfer'} />
                <button type="button" onClick={handleSubmit}>Save and Send</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );

};

export default SendMoneyForm;
